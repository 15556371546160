export default [
  // AUTH
  {
    path: '/admin/login',
    name: 'admin.login',
    component: () => import('@/views/common/auth/pages/Login.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Administrador',
          icon: require('@/assets/images/investidor/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/investidor/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  {
    path: '/admin/auth/nova-senha',
    name: 'change-password',
    component: () => import('@/views/admin/pages/ChangePassword.vue'),
    meta: {
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Administrador',
          icon: require('@/assets/images/investidor/login-icon.svg'),
        },
        appLogoGrande: require('@/assets/images/investidor/cs-logo-login.svg'),
        cardToMoney: require('@/assets/images/investidor/login-image.svg'),
      },
    },
  },
  // ASSIGNOR
  {
    path: '/admin/cedente/dashboard',
    name: 'admin.assignor.dashboard',
    component: () => import('@/views/admin/assignor/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Grupos econômicos',
      skipGoToHome: true,
      indexObject: {
        serviceUrl: 'admin/cedente/grupos_economicos',
        editRouteName: 'admin.assignor.economicGroup.companies',
        datatableTitle: 'Grupos disponíveis na plataforma',
        fields: [
          {
            label: 'Nome do grupo econômico',
            key: 'nome',
          },
          {
            label: 'Pendências',
            key: 'pendency',
          },
          {
            label: 'Status',
            key: 'status',
          },
          {
            label: '',
            key: 'viewButton',
          },
        ],
        filters: [],
      },
    },
  },
  {
    path: '/admin/cedente/grupo-economico/',
    component: () => import('@/layouts/vertical/LayoutVerticalAssignor.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: ':id/empresas',
        name: 'admin.assignor.economicGroup.companies',
        component: () => import('@/views/admin/assignor/pages/Companies.vue'),
        meta: {
          navActiveLink: 'admin.assignor.dashboard',
          action: 'admin-read',
          resource: 'admin-user',
          pageTitle: 'Grupo econômico',
          skipGoToHome: true,
        },
      },
      {
        path: ':id/usuarios',
        name: 'admin.assignor.economicGroup.users',
        component: () => import('@/views/admin/assignor/pages/Users.vue'),
        meta: {
          navActiveLink: 'admin.assignor.dashboard',
          action: 'admin-read',
          resource: 'admin-user',
          pageTitle: 'Grupo econômico',
          skipGoToHome: true,
        },
      },
      {
        path: ':id/convites',
        name: 'admin.assignor.economicGroup.invites',
        component: () => import('@/views/admin/assignor/pages/Invites.vue'),
        meta: {
          navActiveLink: 'admin.assignor.dashboard',
          action: 'admin-read',
          resource: 'admin-user',
          pageTitle: 'Grupo econômico',
          skipGoToHome: true,
        },
      },
    ],
  },
  // INVESTOR
  {
    path: '/admin/investidor/dashboard',
    name: 'admin.investor.dashboard',
    component: () => import('@/views/admin/investor/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Investidor',
      skipGoToHome: true,
    },
  },
  {
    path: '/admin/investidor/convites',
    name: 'admin.investor.invites',
    component: () => import('@/views/admin/investor/pages/Invites.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Gestão de convites',
      skipGoToHome: true,
      actions: [
        {
          title: 'Voltar',
          variant: 'outline-primary',
          routeName: 'admin.investor.dashboard',
          classes: ['bg-white'],
        },
      ],
    },
  },
  {
    path: '/admin/investidor/convites/novo',
    name: 'admin.investor.invites.new',
    component: () => import('@/views/admin/investor/pages/NewInvite.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Convidando usuário',
      skipGoToHome: true,
      actions: [
        {
          title: 'Voltar',
          variant: 'outline-primary',
          routeName: 'admin.investor.invites',
          classes: ['bg-white'],
        },
      ],
    },
  },
  {
    path: '/admin/investidor/:id/editar',
    name: 'admin.investor.editUser',
    component: () => import('@/views/admin/investor/pages/EditUser.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Editando usuário investidor',
      skipGoToHome: true,
    },
  },
  // PROVIDER
  {
    path: '/admin/fornecedor/dashboard',
    name: 'admin.provider.dashboard',
    component: () => import('@/views/admin/provider/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Grupos econômicos',
      skipGoToHome: true,
      indexObject: {
        serviceUrl: 'admin/fornecedor/grupos_economicos',
        editRouteName: 'admin.provider.economicGroup.companies',
        datatableTitle: 'Grupos disponíveis na plataforma',
        fields: [
          {
            label: 'Nome do grupo econômico',
            key: 'nome',
          },
          {
            label: 'Pendências',
            key: 'pendency',
          },
          {
            label: 'Status',
            key: 'status',
          },
          {
            label: '',
            key: 'viewButton',
          },
        ],
        filters: [],
      },
    },
  },
  {
    path: '/admin/fornecedor/grupo-economico/',
    component: () => import('@/layouts/vertical/LayoutVerticalProvider.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
    children: [
      {
        path: ':id/empresas',
        name: 'admin.provider.economicGroup.companies',
        component: () => import('@/views/admin/provider/pages/Companies.vue'),
        meta: {
          navActiveLink: 'admin.provider.dashboard',
          action: 'admin-read',
          resource: 'admin-user',
          pageTitle: 'Grupo econômico',
          skipGoToHome: true,
        },
      },
      {
        path: ':id/usuarios',
        name: 'admin.provider.economicGroup.users',
        component: () => import('@/views/admin/provider/pages/Users.vue'),
        meta: {
          navActiveLink: 'admin.provider.dashboard',
          action: 'admin-read',
          resource: 'admin-user',
          pageTitle: 'Grupo econômico',
          skipGoToHome: true,
        },
      },
      {
        path: ':id/convites',
        name: 'admin.provider.economicGroup.invites',
        component: () => import('@/views/admin/provider/pages/Invites.vue'),
        meta: {
          navActiveLink: 'admin.provider.dashboard',
          action: 'admin-read',
          resource: 'admin-user',
          pageTitle: 'Grupo econômico',
          skipGoToHome: true,
        },
      },
    ],
  },
  // PROFILE
  {
    path: '/admin/perfil',
    name: 'admin.profile',
    component: () => import('@/views/admin/pages/Profile.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Perfil',
      skipGoToHome: true,
    },
  },
  {
    path: '/admin/gestao-dados',
    name: 'admin.data_management',
    component: () => import('@/views/admin/pages/DataManagement.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Dados do Akrual',
    },
  },
  {
    path: '/admin/solicitacoes-emissao',
    name: 'admin.emissions_request',
    component: () => import('@/views/admin/pages/emissions_request/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Solicitações de Emissão',
    },
  },
  {
    path: '/admin/solicitacoes-emissao/:id',
    name: 'admin.emissions_request.show',
    component: () => import('@/views/admin/pages/emissions_request/Show.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Solicitação de Emissão',
    },
  },
  // Visualizar emissões no Admin
  {
    path: '/admin/cedente/emissoes/:serie_id/detalhes',
    name: 'admin.assignor.emission.details',
    component: () => import('@/views/assignor/pages/Detalhes.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Visão Cedente | Detalhamento da emissão',
      pageSubtitle: 'Análise detalhada da emissão.',
    },
  },
  {
    path: '/admin/investidor/emissoes/:serie_id/detalhes',
    name: 'admin.investor.emission.details',
    component: () => import('@/views/investor/pages/Detalhes.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Visão Investidor | Detalhamento da emissão',
      pageSubtitle: 'Análise detalhada da emissão.',
    },
  },
  {
    path: '/admin/prestadordeservico/emissoes/:serie_id/detalhes',
    name: 'admin.provider.emission.details',
    component: () => import('@/views/provider/pages/Detalhes.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Visão Prestador de Serviços | Detalhamento da emissão',
      pageSubtitle: 'Análise detalhada da emissão.',
    },
  },
  // FIM Visualizar emissões no Admin
]
